import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();

  //   console.log(navigate);

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="notFoundApp">
      <div className="noise"></div>
      <div className="overlay"></div>
      <div className="terminal">
        <h1>
          Error <span className="errorcode">404</span>
        </h1>
        <p className="output">La page demandée n'existe pas !</p>
        <p className="output">
          Revenir à l'accueil en cliquant
        </p>
        <a href="/" onClick={goHome}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Sur ce bouton
        </a>
        <p className="output">A bientôt !</p>
      </div>
      <h2 className="noPage">Page introuvable !!</h2>
    </div>
  );
};

export default NotFound;
