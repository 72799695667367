import React from "react";
import { makeStyles } from "@material-ui/core";
import { Typography, Box } from "@material-ui/core";
import Navbar from "./Navbar";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#233",
  },
  timeLine: {
    position: "relative",
    padding: "1rem",
    margin: "3 auto",
    "&:before": {
      content: "''",
      position: "absolute",
      height: "100%",
      border: "1px solid tan",
      right: "40px",
      top: "0",
    },
    "&:after": {
      content: "''",
      display: "table",
      clear: "both",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem",
      "&:before": {
        left: "calc(50% - 1px)",
        right: "auto",
      },
    },
  },
  timeLineItem: {
    padding: "1rem",
    borderBottom: "2px solid tan",
    position: "relative",
    margin: "1rem 3rem 1rem 1rem",
    clear: "both",
    "&:after": {
      content: "''",
      position: "absolute",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      right: "-0.625rem",
      top: "calc(50% - 5px)",
      borderStyle: "solid",
      borderColor: "tomato tomato transparent transparent",
      borderWidth: "0.625rem",
      transform: "rotate(45deg)",
    },
    [theme.breakpoints.up("md")]: {
      width: "44%",
      margin: "1rem",
      "&:nth-of-type(2n)": {
        float: "right",
        margin: "1rem",
        borderColor: "tan",
      },
      "&:nth-of-type(2n):before": {
        right: "auto",
        left: "-0.625rem",
        borderColor: "transparent transparent tomato tomato",
      },
    },
  },

  timeLineYear: {
    textAlign: "center",
    maxWidth: "9.375rem",
    margin: "0 3rem 0 auto",
    background: "tomato",
    color: "white",
    lineHeight: 1,
    padding: "0.5rem 0 1rem",
    "&:before": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      margin: "0 auto",
      "&:nth-of-type(2n)": {
        display: "none",
      },
    },
  },
  heading: {
    color: "tomato",
    padding: " 6rem 0 3rem 0",
    textTransform: "uppercase",
  },
  subHeading: {
    color: "white",
    padding: "0",
    textTransform: "uppercase",
  },
}));

const Profile = () => {
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <Box component="header" className={classes.mainContainer}>
        <Typography
          variant="h4"
          align="center"
          className={`${classes.heading} marginTop`}
        >
          Profil & experience
        </Typography>
        <Box component="div" className={classes.timeLine}>
          <Typography
            variant="h2"
            className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          >
            Profil
          </Typography>

          <Box component="div" className={classes.timeLineItem}>
            <Typography
              variant="h4"
              align="center"
              className={classes.subHeading}
            ></Typography>

            <Typography variant="h6" align="center" style={{ color: "tan" }}>
              Pendant ma formation à l'IFOCOP, j'ai étudié des langages comme
              (HTML, CSS, JS, PHP, Wordpress). Lors de mon stage, j'ai appris à
              utiliser React (niveau débutant). Ensuite, j'ai travaillé pendant
              un an comme développeur web à l'INALCO, où j'ai eu des missions
              principalement en frontend, comme la refonte de l'interface des
              applications, mais également l'élaboration d'une application en
              PHP.
            </Typography>
          </Box>

          <Typography
            variant="h2"
            className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          >
            2022
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography
              variant="h5"
              align="center"
              className={classes.subHeading}
            >
              Mai 2022-Mai 2023
            </Typography>
            <Typography variant="h6" align="center" style={{ color: "tomato" }}>
              Développeur web à l'INALCO
            </Typography>
            <Typography variant="h6" align="center" style={{ color: "tan" }}>
              Durant mon année en tant que développeur web à l'INALCO, j'ai été
              principalement chargé de projets liés au développement frontend,
              impliquant la modernisation des interfaces d'applications
              existantes, ainsi que la création d'une application en PHP.
            </Typography>
          </Box>
          <Typography
            variant="h2"
            className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          >
            2021
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography
              variant="h5"
              align="center"
              className={classes.subHeading}
            >
              Mars 2021-juillet 2021
            </Typography>
            <Typography variant="h6" align="center" style={{ color: "tomato" }}>
              Stage chez BLOOM alternance
            </Typography>
            <Typography variant="h6" align="center" style={{ color: "tan" }}>
              J'ai effectué mon stage pratique chez Bloom, une société
              spécialisée dans la recherche d'alternance. J'ai étroitement
              travaillé avec le développeur senior de la start-up,
              essentiellement sur ReactJS, en créant le front des pages (offres
              d'emploi), en modifiant le CSS du back-office en SASS. J'ai
              également appris le fonctionnement de ReactJS (dependencies,
              Hooks, Semantic-UI, MUI).
            </Typography>
          </Box>

          <Typography
            variant="h2"
            className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          >
            2020
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography
              variant="h5"
              align="center"
              className={classes.subHeading}
            >
              décembre 2020-mars 2021
            </Typography>
            <Typography variant="h6" align="center" style={{ color: "tomato" }}>
              Formation à l'IFOCOP
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ color: "tan" }}
            >
              J'ai suivi la formation de développeur intégrateur web BAC + 4
              (RNCP 6) à l'IFOCOP, pendant laquelle j'ai étudié du HTML, CSS,
              Bootstrap pour les langages de conception, Javascript et jQuery
              pour la dynamique, ainsi que PHP & mySQL pour les requêtes en base
              de données. Mais également Wordpress pour le e-commerce.
            </Typography>
          </Box>
          <Typography
            variant="h2"
            className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          >
            2015-2020
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography
              variant="h5"
              align="center"
              className={classes.subHeading}
            >
              2015-2020
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ color: "tomato" }}
            >
              Employé polyvalent chez Subway®.
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ color: "tan" }}
            >
              Accueil de clients, préparation de sandwichs, encaissement,
              inventaire, nettoyage des locaux, manutention (livraison).
            </Typography>
          </Box>
          <Typography
            variant="h2"
            className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          >
            2016 - 2018
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography
              variant="h5"
              align="center"
              className={classes.subHeading}
            >
              2016-2018
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ color: "tomato" }}
            >
              Barman à l'Engrenage bistro.
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ color: "tan" }}
            >
              Accueil des clients, organisation d'événements (anniversaire,
              after-work...) préparation de cocktails.
            </Typography>
          </Box>
          <Typography
            variant="h2"
            className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          >
            2014-2015
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography variant="h5" className={classes.subHeading}>
              2014-2015
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ color: "tomato" }}
            >
              Moniteur étudiant à la bibliothèque universitaire Jean Dausset.
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ color: "tan" }}
            >
              Accueil d'étudiant, prêt et retour de livres. Couverture et
              rangement des livres dans les rayons.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Profile;
