import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@material-ui/core";
import Navbar from "./Navbar";
import yogaApp from "../images/yoga.png";
import justePrix from "../images/juste-prix.png";
import panameBiking from "../images/paname-biking.png";
import annonceo from "../images/annonceo.png";
import passwordGenerator from "../images/password-generator.png";
import adressesGeneriques from "../images/adresses-generiques.png";
import nodejs_ecommerce from "../images/nodejs_ecommerce.jpg";

const useStyles = makeStyles({
  mainContainer: {
    background: "#233",
    height: "100%",
  },
  cardContainer: {
    maxWidth: 700,
    margin: "2rem auto",
  },
});

const Portfolio = () => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.mainContainer} id="portfolio">
      <Navbar />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        id="portfolio-cards"
      >
        {/* Project1 */}
        <Grid item xs={10} sm={8} md={6}>
          <Card className={classes.cardContainer} id="grid-items">
            <CardActionArea>
              <CardMedia
                component="img"
                alt="annonceo"
                height="500"
                image={annonceo}
              />
              <CardContent style={{ textAlign: "center", margin: "0 2vw" }}>
                <Typography gutterBottom variant="h5">
                  Annonceo
                </Typography>
                <Typography variant="subtitle1">
                  Annonceo est un site de petites annonces créé en PHP & mySQL
                  en binome dans le cadre de mon 2ème projet de formation
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                variant="contained"
                size="small"
                color="primary"
                href="https://annonceo.fares-takorabt.fr/"
                target="_blank"
              >
                Visiter le site
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Project2 */}
        <Grid item xs={10} sm={8} md={6}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="paname biking"
                height="500"
                image={panameBiking}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Paname biking
                </Typography>
                <Typography
                  style={{ textAlign: "center", margin: "0 2vw" }}
                  variant="subtitle1"
                >
                  Paname biking est un site e-commerce, spécialisé dans la vente
                  des vélos et des articles de vélos, créé sous Wordpress dans
                  le cadre de ma soutenance pendant ma formation à l'IFOCOP.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                variant="contained"
                size="small"
                color="primary"
                href="https://panamebiking.fares-takorabt.fr/"
                target="_blank"
              >
                Visiter le site
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Project3 */}
        <Grid item xs={10} sm={10} md={6}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="juste prix"
                height="500"
                image={justePrix}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Devinez le juste prix
                </Typography>
                <Typography color="primary" variant="subtitle1"></Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                variant="contained"
                size="small"
                color="primary"
                href="https://juste-prix.fares-takorabt.fr/"
                target="_blank"
              >
                Visiter le site
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Project4 */}
        <Grid item xs={10} sm={10} md={6}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="yoga app"
                height="500"
                image={yogaApp}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Yoga app
                </Typography>
                <Typography color="primary" variant="subtitle1"></Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                variant="contained"
                size="small"
                color="primary"
                href="https://yoga-app.fares-takorabt.fr/"
                target="_blank"
              >
                Visiter le site
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Project5 */}
        <Grid item xs={10} sm={10} md={6}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="password generator"
                height="500"
                image={passwordGenerator}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Password generator
                </Typography>
                <Typography color="primary" variant="subtitle1"></Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                variant="contained"
                size="small"
                color="primary"
                href="https://password-generator.fares-takorabt.fr/"
                target="_blank"
              >
                Visiter le site
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Project6 */}
        <Grid item xs={10} sm={10} md={6}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Adresses génériques"
                height="500"
                image={adressesGeneriques}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Adresses génériques <br></br>
                  (Projet réalisé pendant mon experience à l'INALCO)
                </Typography>
                <Typography color="primary" variant="subtitle1"></Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                variant="contained"
                size="small"
                color="primary"
                href="https://adresses-generiques.fares-takorabt.fr/"
                target="_blank"
              >
                Visiter le site
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Project7 */}
        <Grid item xs={10} sm={10} md={6}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="API E-commerce"
                height="500"
                image={nodejs_ecommerce}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  API E-commerce <br></br> back end en NodeJS deployée sur
                  render.com
                </Typography>
                <Typography color="primary" variant="subtitle1"></Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                variant="contained"
                size="small"
                color="primary"
                href="https://mern-ecommerce-api-7rt5.onrender.com/"
                target="_blank"
              >
                Visiter le site
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Autres projets */}
        <Grid item xs={10} sm={10} md={6}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardContent style={{ textAlign: "center" }}>
                <Typography gutterBottom variant="h5" className="last-content">
                  Autres projets
                  <Button
                    variant="contained"
                    size="large"
                    a
                    href="https://bitcoin-api.fares-takorabt.fr/"
                    target="_blank"
                  >
                    Bitcoin app
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    a
                    href="https://bubble-shooter.fares-takorabt.fr/"
                    target="_blank"
                  >
                    Bubble shooter
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    a
                    href="https://form-checker.fares-takorabt.fr/"
                    target="_blank"
                  >
                    Form checker
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    a
                    href="https://meal-app.fares-takorabt.fr/"
                    target="_blank"
                  >
                    Meal app
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    a
                    href="https://blague-app.fares-takorabt.fr/"
                    target="_blank"
                  >
                    Blague app art
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    a
                    href="https://url-shortener.fares-takorabt.fr/"
                    target="_blank"
                  >
                    URL shortener (PHP)
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    a
                    href="https://farestakorabt.github.io/react-maps-appli/"
                    target="_blank"
                  >
                    World flags app
                  </Button>
                </Typography>
              </CardContent>
            </CardActionArea>
            <Typography variant="subtitle1" className="last-text">
              Projets réalisés pendant mes cours sur Udemy et Youtube.
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Portfolio;
