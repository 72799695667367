import React from "react";
import Navbar from "./Navbar";
import {
  Box,
  Grid,
  Card,
  CardActions,
  ListItem,
  Avatar,
  Button,
  Link,
  Typography,
  Container,
} from "@material-ui/core";
import Openclass from "../images/openclassrooms.png";
import Udemy from "../images/udemy.png";
import Github from "../images/github.png";
import Linkedin from "../images/linkedin.png";

const Links = () => {
  return (
    <Box component="div" className="linksBoxPage">
      <Navbar />
      <Typography
        className="linksMesProfils"
        style={{
          color: "tomato",
          textAlign: "center",
        }}
      >
        Mes profils
      </Typography>
      <Container className="linksBoxDiv">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          id="portfolio-cards"
        >
          {/* Openclassrooms */}
          <Grid>
            <Card>
              <CardActions>
                <ListItem>
                  <Avatar src={Openclass} alt="logo Openclassrooms" />
                  <Button variant="contained" className="containedButton">
                    <Link
                      href="https://openclassrooms.com/fr/members/24vzkp54z443/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linksButtons"
                    >
                      Openclassrooms
                    </Link>
                  </Button>
                </ListItem>
              </CardActions>
            </Card>
          </Grid>

          {/* Udemy */}
          <Grid>
            <Card>
              <CardActions>
                <ListItem>
                  <Avatar src={Udemy} alt="logo Udemy" />
                  <Button variant="contained">
                    <Link
                      href="https://www.udemy.com/user/fares-560/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linksButtons"
                    >
                      Udemy
                    </Link>
                  </Button>
                </ListItem>
              </CardActions>
            </Card>
          </Grid>

          {/* Github */}
          <Grid>
            <Card>
              <CardActions>
                <ListItem>
                  <Avatar src={Github} alt="logo Github" />
                  <Button variant="contained">
                    <Link
                      href="https://github.com/farestakorabt/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linksButtons"
                    >
                      Github
                    </Link>
                  </Button>
                </ListItem>
              </CardActions>
            </Card>
          </Grid>

          {/* Linkedin */}
          <Grid>
            <Card>
              <CardActions>
                <ListItem>
                  <Avatar src={Linkedin} alt="logo Linkedin" />
                  <Button variant="contained">
                    <Link
                      href="https://www.linkedin.com/in/fares-takorabt/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linksButtons"
                    >
                      Linkedin
                    </Link>
                  </Button>
                </ListItem>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Links;
