import React from "react";
import { makeStyles } from "@material-ui/styles";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
// import YouTube from "@material-ui/icons/YouTube";
import GitHub from "@material-ui/icons/GitHub";
import LinkedIn from "@material-ui/icons/LinkedIn";

const useStyles = makeStyles({
  root: {
    "& .MuiBottomNavigationAction-root": {
      minWidth: 0,
      maxWidth: 250,
    },
    "& .MuiSvgIcon-root": {
      fill: "tan",
    },
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <BottomNavigation width="auto" style={{ background: "#222" }} id="bottom-navigation">
      <BottomNavigationAction
        className={classes.root}
        style={{ padding: 5 }}
        icon={<LinkedIn />}
        a
        href="https://www.linkedin.com/in/fares-takorabt/"
        target="_blank"
      ></BottomNavigationAction>

      <BottomNavigationAction
        className={classes.root}
        style={{ padding: 5 }}
        icon={<GitHub />}
        a
        href="https://github.com/farestakorabt"
        target="_blank"
      ></BottomNavigationAction>

      {/* <BottomNavigationAction
        className={classes.root}
        style={{ padding: 5 }}
        icon={<YouTube />}
        a
        href="https://www.youtube.com/channel/UC7DIOjeJiAHx783ofAX9PAA/playlists"
        target="_blank"
      ></BottomNavigationAction> */}
    </BottomNavigation>
  );
};

export default Footer;
