import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Portfolio from "./components/Portfolio";
import Contacts from "./components/Contacts";
import CV from "./components/CV";
import NotFound from "./components/NotFound/NotFound";
import Links from "./components/Links";

function App() {
  return (
    <React.Fragment>
      <CssBaseline />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Profil" element={<Profile />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Contacts" element={<Contacts />} />
        <Route path="/Links" element={<Links />} />
        <Route path="/CV" element={<CV />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
