import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
  Button,
} from "@material-ui/core";
import Navbar from "./Navbar";
import CV_Takorabt_Fares from "../images/CV_Takorabt_Fares.png";
import CV_fares_TAKORABT_pdf from "../images/CV_Takorabt_Fares.pdf";
// import CV_Takorabt_Fares_testeur_qa from "../images/CV_Takorabt_Fares_testeur_qa.png";
// import CV_fares_TAKORABT_testeur_qa_pdf from "../images/CV_Takorabt_Fares_testeur_qa.pdf";

const useStyles = makeStyles({
  mainContainer: {
    background: "#233",
  },
  cardContainer: {
    margin: "1rem auto 2rem auto",
  },
});

const CV = () => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.mainContainer} id="CV">
      <Navbar />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={10} sm={10} md={4}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="CV_Takorabt_Fares"
                image={CV_Takorabt_Fares}
              />
            </CardActionArea>
            <CardActions>
              <Button
                variant="contained"
                size="small"
                color="primary"
                href={CV_fares_TAKORABT_pdf}
                target="_blank"
              >
                Télécharger mon CV
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* <Grid item xs={10} sm={10} md={4}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="CV_Takorabt_Fares"
                image={CV_Takorabt_Fares_testeur_qa}
              />
            </CardActionArea>
            <CardActions>
              <Button
                variant="contained"
                size="small"
                color="primary"
                href={CV_fares_TAKORABT_testeur_qa_pdf}
                target="_blank"
              >
                Télécharger mon CV
              </Button>
            </CardActions>
          </Card>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default CV;
